<template>
  <div>
    <Header :link-idx="2" />
    <div class="g-min-height mt80 pt30 pb60 w1220">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>下单</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="line-title fs22 col-3 fwb mtb40">一号标书</div>
      <div class="flex-box mb20">
        <div class="key">标书类型</div>
        <div class="value-box">
          <el-dropdown class="dropdown pointer" placement="bottom-start" trigger="click" @command="handleCommand1" @visible-change="toggleDropdown1">
            <div class="el-dropdown-link flex-box" :class="{ 'col-3': bidType1, 'col-9': !bidType1 }">
              <div class="flex-1">{{ bidType1 ? bidType1 : '请选择标书类型' }}</div>
              <i class="el-icon--right" :class="{ 'el-icon-arrow-down': dropdownVis1 == false, 'el-icon-arrow-up': dropdownVis1 == true }"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="电子标书">电子标书</el-dropdown-item>
              <el-dropdown-item command="纸质标书">纸质标书</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="flex-box mb20">
        <div class="key">姓名</div>
        <div class="value-box"><input type="text" v-model="name"   class="mtb20 m-input" placeholder="请输入您的姓名" /></div>
      </div>
      <div class="flex-box mb20">
        <div class="key">身份证</div>
        <div class="value-box"><input type="text" v-model="idnum"   class="mtb20 m-input" placeholder="请输入您的身份证号码" /></div>
      </div>
      <div class="flex-box mb20">
        <div class="key">手机号</div>
        <div class="value-box"><input type="text" v-model="mobile"  class="mtb20 m-input" placeholder="请输入您标书上的手机号" /></div>
      </div>
      <div class="flex-box">
        <div class="key">上传标书</div>
        <div class="upload-wrap flex-box flex-wrap flex-col-start">
          <div v-if="image" class="item">
            <img class="icon-del pointer"  @click="delImage"  src="@/assets/icon/icon_reduce.png" alt="" />
            <img class="icon-pdf" :src="image_url"  alt="" />
          </div>
          <div v-if="!image" class="item">
            <img class="icon-add" src="@/assets/icon/icon_add.png" alt="" />
            <div class="fs16 col-9 tc">上传标书</div>
            <input class="file pointer" type="file" @change="changeFile" />
          </div>
        </div>
      </div>
      <div class="line-title fs22 col-3 fwb mtb40">二号标书</div>
      <div class="flex-box mb20">
        <div class="key">二号标书类型</div>
        <div class="value-box">
          <el-dropdown class="dropdown pointer" placement="bottom-start" trigger="click" @command="handleCommand2" @visible-change="toggleDropdown2">
            <div class="el-dropdown-link flex-box" :class="{ 'col-3': bidType2, 'col-9': !bidType2 }">
              <div class="flex-1">{{ bidType2 ? bidType2 : '请选择标书类型' }}</div>
              <i class="el-icon--right" :class="{ 'el-icon-arrow-down': dropdownVis2 == false, 'el-icon-arrow-up': dropdownVis2 == true }"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="电子标书">电子标书</el-dropdown-item>
              <el-dropdown-item command="纸质标书">纸质标书</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="flex-box mb20">
        <div class="key">姓名</div>
        <div class="value-box"><input type="text"  v-model="name2" class="mtb20 m-input" placeholder="请输入您的姓名" /></div>
      </div>
      <div class="flex-box mb20">
        <div class="key">身份证</div>
        <div class="value-box"><input type="text" v-model="idnum2"  class="mtb20 m-input" placeholder="请输入您的身份证号码" /></div>
      </div>
      <div class="flex-box mb20">
        <div class="key">手机号</div>
        <div class="value-box"><input type="text" v-model="mobile2"   class="mtb20 m-input" placeholder="请输入您标书上的手机号" /></div>
      </div>
      <div class="flex-box">
        <div class="key">上传标书</div>
        <div class="upload-wrap flex-box flex-wrap flex-col-start">
          <div v-if="image2" class="item">
            <img class="icon-del pointer"  @click="delImage2"  src="@/assets/icon/icon_reduce.png" alt="" />
            <img class="icon-pdf" :src="image2_url"  alt="" />
          </div>
          <div v-if="!image2" class="item">
            <img class="icon-add" src="@/assets/icon/icon_add.png" alt="" />
            <div class="fs16 col-9 tc">上传标书</div>
            <input class="file pointer" type="file" @change="changeFile2" />
          </div>
        </div>
      </div>
      <div class="line-title fs22 col-3 fwb mtb40">代拍套餐</div>
      <div class="flex-box mb40">
        <div class="key">代拍价格</div>
        <div class="fs16 yellow value-box">
          <!--
          <span class="mr10">*</span>
          <span>10000.00¥</span>
          -->
          <el-dropdown class="dropdown pointer" placement="bottom-start" trigger="click" @command="handleCommand3" @visible-change="toggleDropdown3">
            <div class="el-dropdown-link flex-box col-3">
              <div class="flex-1">*{{price}}¥</div>
              <i class="el-icon--right" :class="{ 'el-icon-arrow-down': dropdownVis3 == false, 'el-icon-arrow-up': dropdownVis3 == true }"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="8888.00">8888.00¥</el-dropdown-item>
              <el-dropdown-item command="11888.00">11888.00¥</el-dropdown-item>
              <el-dropdown-item command="15888.00">15888.00¥</el-dropdown-item>
              <el-dropdown-item command="26888.00">26888.00¥</el-dropdown-item>
              <el-dropdown-item command="6888.00">6888.00¥</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="flex-box mb40">
        <div class="key">代拍次数</div>
        <div class="fs16 yellow value-box">
          <el-dropdown class="dropdown pointer" placement="bottom-start" trigger="click" @command="handleCommand4" @visible-change="toggleDropdown4">
            <div class="el-dropdown-link flex-box col-3">
              <div class="flex-1">*{{count}}次</div>
              <i class="el-icon--right" :class="{ 'el-icon-arrow-down': dropdownVis4 == false, 'el-icon-arrow-up': dropdownVis4 == true }"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="6">6次</el-dropdown-item>
              <el-dropdown-item command="3">3次</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="flex-box">
        <div class="key">赔付金额</div>
        <div class="fs16 yellow">
          <span class="mr10">*</span>
          <span>{{cprice}}¥</span>
        </div>
      </div>
      <div  @click="setOrder" class="btn-sign pointer">签署合同</div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/header/Header.vue'
export default {
  name: 'PlaceOrderSecond',
  components: { Header },
  data() {
    return {
      bidType1: '',
      bidType2: '',
      bidText1:'',
      bidText2:'',
      price:'8888.00',
      count:6,
      cprice:2588,
      dropdownVis1: false,
      dropdownVis2: false,
      dropdownVis3: false,
      dropdownVis4: false,
      set_id:6,
			name:'',
			idnum:'',
			mobile:'',
			image:'',
			image_url:'/static/images/camera.png',
			meal:[],
			typeIdx: 1,
			typeIdx2: 1,
			name2:'',
			idnum2:'',
			mobile2:'',
			image2:'',
			image_url2:'/static/images/camera.png',
			id:0,
    }
  },
  created(){
    this.token = this.$storage.get('token');
     if(!this.token){
       this.$router.push('/login');
     }
  },
  methods: {
    handleCommand1(command) {
      this.bidType1 = command
    },
    toggleDropdown1(boolean) {
      this.dropdownVis1 = boolean
    },
    handleCommand2(command) {
      this.bidType2 = command
    },
    toggleDropdown2(boolean) {
      this.dropdownVis2 = boolean
    },
    handleCommand3(command) {
      this.price= command;
      this.getcPrice()
    },
    toggleDropdown3(boolean) {
      this.dropdownVis3 = boolean
    },
    handleCommand4(command) {
      this.count = command;
      this.getcPrice()
    },
    toggleDropdown4(boolean) {
      this.dropdownVis4 = boolean
    },
    getcPrice(){
        var params = {
          	price: this.price,
						count: this.count,
						token: this.token
        };
        this.$http.get("/api/team/findmeal", {
          params
        }).then((res) => {
						if(res.data.code==1){
							this.set_id = res.data.data.id;
							this.cprice =  res.data.data.pei;
						}else{
							this.$message.error(res.data.msg);
							return false;
						}
           
        });     
    },   
    delImage(){
       this.image = '';
    },
    changeFile(e){
            // 上传图像通常要将文件参数放在fromdata 对象中
            // 获取文件信息 e.target.files
            //console.log(e.target.files[0]);
            let formData=new FormData()
            // 向formData实例中追加属性 (参数1：属性名 参数2：属性值)
            formData.append('file',e.target.files[0])
            formData.append('token',this.token)
            var loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading'
            });
            this.$http.post('api/common/upload',formData).then(res=>{
                    loading.close();
                    if (res.data.code == 1) {
												this.image = res.data.data.url;
												this.image_url = res.data.data.full_url;
										} else {
                        this.$message.error(res.data.msg);
										}
            })
    },
    delImage2(){
       this.image2 = '';
    },
    changeFile2(e){
            // 上传图像通常要将文件参数放在fromdata 对象中
            // 获取文件信息 e.target.files
            //console.log(e.target.files[0]);
            let formData=new FormData()
            // 向formData实例中追加属性 (参数1：属性名 参数2：属性值)
            formData.append('file',e.target.files[0])
            formData.append('token',this.token)
            var loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading'
            });
            this.$http.post('api/common/upload',formData).then(res=>{
                    loading.close();
                    if (res.data.code == 1) {
												this.image2 = res.data.data.url;
												this.image2_url = res.data.data.full_url;
										} else {
                        this.$message.error(res.data.msg);
										}
            })
    },
    setOrder(){
	    var that = this;
			let reg = /^[1][0-9]{10}$/ ;
			let idreg = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      if(!that.bidType1){
        that.$message.error("请选择标书类型");
				return false;
      }
      if(that.bidType1=='电子标书'){
           that.bidText1 = 1;
      }
      if(that.bidType1=='纸质标书'){
           that.bidText1 = 2;
      }
			if(!that.name){
        that.$message.error("请输入姓名");
				return false;
			}
			if(!that.idnum){
        that.$message.error("请输入身份证");
				return false;
			}	
		    //判断身份证格式
			if (!idreg.test(that.idnum)) {        
         that.$message.error("身份证号码不正确");
				 return false;
			}
			
			if(!that.mobile){
        that.$message.error("请输入手机号码");
				return false;
			}	
			//判断手机号码格式
			if (!reg.test(that.mobile)) {        
        that.$message.error("手机号码不正确");
				return false;
			}

      if(!that.bidType2){
        that.$message.error("请选择二号标书类型");
				return false;
      }
      if(that.bidType2=='电子标书'){
           that.bidText2 = 1;
      }
      if(that.bidType2=='纸质标书'){
           that.bidText2 = 2;
      }

			if(!that.name2){
        that.$message.error("请输入姓名");
				return false;
			}
			if(!that.idnum2){
        that.$message.error("请输入身份证");
				return false;
			}	
		    //判断身份证格式
			if (!idreg.test(that.idnum2)) {        
         that.$message.error("身份证号码不正确");
				 return false;
			}
			
			if(!that.mobile2){
        that.$message.error("请输入手机号码");
				return false;
			}	
			//判断手机号码格式
			if (!reg.test(that.mobile2)) {        
        that.$message.error("手机号码不正确");
				return false;
			}

      that.$storage.set('type',that.bidText1);
      that.$storage.set('name',that.name);
			that.$storage.set('idnum',that.idnum);
      that.$storage.set('mobile',that.mobile);
      that.$storage.set('image',that.image);

      that.$storage.set('type2',that.bidText2);
      that.$storage.set('name2',that.name2);
			that.$storage.set('idnum2',that.idnum2);
      that.$storage.set('mobile2',that.mobile2);
      that.$storage.set('image2',that.image2);

      that.$router.push({ path: '/signContract',query:{id:that.set_id,type:1} })
    }
  }
}
</script>
<style lang="less" scoped>
.team {
  .img-head {
    display: block;
    width: 102px;
    height: 102px;
    border-radius: 50%;
  }
  .team-label {
    width: 240px;
    height: 30px;
    background: #d4f2fc;
    border-radius: 4px;
    font-size: 16px;
    color: #0a9bb8;
    line-height: 30px;
    text-align: center;
  }
}
.line-title {
  position: relative;
  padding-left: 26px;
}
.line-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 6px;
  height: 30px;
  background: var(--yellow);
}
.key {
  width: 160px;
  font-size: 18px;
  color: #333333;
  line-height: 22px;
}
.value-box {
  padding: 0 30px;
  width: 1060px;
  height: 70px;
  background: #f6f6f6;
  border-radius: 10px;
  .el-dropdown {
    display: block;
    height: 70px;
  }
  .el-dropdown-link {
    height: 70px;
    font-size: 16px;
    line-height: 70px;
  }
  .m-input {
    font-size: 16px;
    line-height: 30px;
  }
}
.el-dropdown-menu {
  width: 1000px;
}

.upload-wrap {
  .item {
    position: relative;
    margin: 0 15px 0 0;
    padding: 27px 0 0;
    width: 106px;
    height: 106px;
    background: #fafafa;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    .icon-del {
      position: absolute;
      right: -11px;
      top: -11px;
      display: block;
      width: 22px;
      height: 22px;
    }
    .icon-pdf {
      margin: 0 auto;
      display: block;
      width: 51px;
      height: 53px;
    }
    .icon-add {
      margin: 0 auto 10px;
      display: block;
      width: 27px;
      height: 27px;
    }
    .file {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
}
.btn-sign {
  margin: 100px auto 0;
  display: block;
  width: 300px;
  height: 70px;
  background: #f6e65f;
  border-radius: 10px 10px 10px 10px;
  font-size: 20px;
  color: #333333;
  line-height: 70px;
  text-align: center;
}
</style>
