<template>
  <div>
    <Header :link-idx="3" />
    <div class="g-min-height pt30 pb60 w1220 fs16 col-3">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/myOrder' }">我的订单</el-breadcrumb-item>
        <el-breadcrumb-item>我的合同</el-breadcrumb-item>
      </el-breadcrumb>
      <div v-show="order.team_id>0"  class="team ptb40 mb30 flex-box m-hairline--bottom">
        <img class="img-head mr30" :src="order.team.logo_image_url" alt="" />
        <div class="flex-1">
          <div class="fs30 col-3 mb20">{{order.team.name}}</div>
          <div class="team-label">{{order.label}}</div>
        </div>
      </div>
      <div class="contract">
        <div class="mb30">
          甲方自愿支付
          <span class="yellow">{{order.price}}</span>
          元服务费，委托乙方代拍
          <span class="yellow">{{order.count}}</span>
          次,如乙方超出期限未完成代拍任务，需赔偿甲方
          <span class="yellow">{{order.compensation}}</span>
          元赔付金。漏拍一次赔付100元。因乙方原因未完成代拍任务的，甲方无需支付服务费给乙方。
        </div>
        <div v-for="(item,index) in contract" :key="index" > 
           <div class="line-title">{{item.title}}</div>
           <div class="mb30">{{item.content}}</div>
       </div>
        <div class="pt40 flex-box fs22">
          <div class="flex-1">
            <div class="mb20">甲方：{{order.phone}}</div>
            <div class="mb30">时间：{{order.create_time}}</div>
            <img class="icon-agree" src="@/assets/icon/icon_agree.png" alt="" />
          </div>
          <div class="flex-1">
            <div class="mb20">乙方：技蓝网络科技（上海）有限公司</div>
            <div>时间：{{order.create_time}}</div>
            <img class="icon-agree" src="@/assets/icon/icon_agree.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :show-close="false" :before-close="closeDialog" width="400px" custom-class="contract-dialog radius10">
      <img class="icon-success" src="@/assets/icon/icon_success.png" alt="" />
      <div class="tip">合同签署成功，请等待后台确认</div>
      <div class="tip">【并在我的订单完成后续操作】</div>
      <div class="btn-sure pointer">确定</div>
    </el-dialog>
  </div>
</template>
<script>
import Header from '@/components/header/Header.vue'
export default {
  name: 'Contract',
  components: { Header },
  data() {
    return {
        dialogVisible: false,
				contract: [],
				order: {
          team:{
            logo_image_url:''
          }
        },
				token: "",
    }
  },
  created(){
    this.id =  this.$route.query.id ;
    this.token = this.$storage.get('token');
    if(!this.token){
       this.$router.push('/login');
    }
  },
  mounted(){
    this.getOrderDetail()
  },
  methods: {
    openDialog() {
      this.dialogVisible = true
    },
    closeDialog() {
      this.dialogVisible = false
    },
    getOrderDetail(){
        var that =  this;
        var params = {id:that.id,token:that.token};
        that.$http.get("/api/order/contract", {
          params
        }).then((res) => {
            if(res.data.code==1){
              that.order = res.data.data.order;
              that.contract =res.data.data.contract
            }else{
              setTimeout(function(){
                //跳转首页
                that.$router.push('/myOrder');
              },1500);
            }
        });        
     }
  }
}
</script>
<style lang="less" scoped>
.team {
  .img-head {
    display: block;
    width: 102px;
    height: 102px;
    border-radius: 50%;
  }
  .team-label {
    width: 240px;
    height: 30px;
    background: #d4f2fc;
    border-radius: 4px;
    font-size: 16px;
    color: #0a9bb8;
    line-height: 30px;
    text-align: center;
  }
}
.contract {
  line-height: 32px;
  .btn-sign {
    display: block;
    width: 196px;
    height: 70px;
    background: #f6e65f;
    border-radius: 99px;
    font-size: 18px;
    line-height: 70px;
    text-align: center;
  }
  .icon-agree {
    display: block;
    width: 143px;
    height: 104px;
  }
}
.line-title {
  position: relative;
  margin: 0 0 20px;
  padding-left: 26px;
  font-size: 20px;
  font-weight: bold;
}
.line-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 6px;
  height: 30px;
  background: var(--yellow);
}
.contract-dialog {
  .icon-success {
    margin: 0 auto 20px;
    display: block;
    width: 84px;
    height: 84px;
  }
  .tip {
    font-size: 16px;
    color: #333333;
    line-height: 30px;
    text-align: center;
  }
  .btn-sure {
    margin: 40px auto 0;
    width: 200px;
    height: 40px;
    background: #f6e65f;
    border-radius: 4px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
</style>
