<template>
  <div>
    <div class="g-head-box flex-box col-3 mb30">
      <div class="fs18">联系客服</div>
    </div>
    <div class="kefu fs18 col-3">客服电话：{{kefu}}</div>
  </div>
</template>
<script>
export default {
  name: 'About',
  data() {
    return {
      leftIdx: 0,
      kefu:"400-820-7735"
    }
  },
 created(){
    this.getTeach()
  },
  methods:{
     getTeach(){
        var that =  this;
        var params = {action:'pc'};
        that.$http.get("/api/team/meal", {
          params
        }).then((res) => {
            that.kefu = res.data.data.phoneNumber
        });        
     }
  }
}
</script>
<style lang="less" scoped>
.kefu {
  padding: 0 0 0 40px;
}
</style>
