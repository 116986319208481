<template>
  <div>
    <div class="g-head-box flex-box col-3 mb30">
      <div class="fs18">关于我们</div>
    </div>
    <div class="about fs16 col-3">
      <img class="mb25" style="width:550px;height:auto" :src="image" alt="" />
      <div v-html="artcile.counten"></div>
      <div v-html="artcile.content"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'About',
  data() {
    return {
      leftIdx: 0,
      image:'http://www.qmdaipai.com/uploads/20190523/13ceaac10d56aae4bd2a27a678edf08f.jpg',
      artcile:{
         content:'',
      }
    }
  },
 created(){
    this.getTeach()
  },
  methods:{
     getTeach(){
        var that =  this;
        var params = {action:'pc'};
        that.$http.get("/api/common/aboutus", {
          params
        }).then((res) => {
            that.artcile = res.data.data
        });        
     }
  }
}
</script>
<style lang="less" scoped>
.about {
  padding: 0 0 0 40px;
  .img-logo {
    display: block;
    width: 238.1px;
    height: 91.74px;
  }
  .intro {
    margin: 0 0 85px;
    line-height: 28px;
  }
}
</style>
