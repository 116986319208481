<template>
  <div>
    <Header :link-idx="2" />
    <div class="mt80 g-min-height">
      <div class="team-detail pt30 pb40 w1220">
        <el-breadcrumb class="mb40" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/team' }">团队</el-breadcrumb-item>
          <el-breadcrumb-item>团队详情</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 团队轮播图 -->

        <el-carousel trigger="click" height="205px" :autoplay="true" :interval="3000" :loop="true" arrow="never">
          <el-carousel-item v-for="item in 3" :key="item">
            <div>
              <div class="flex-box mb30">
                <div class="line-title1 fs18 fwb col-3">上海理工拍牌团队</div>
                <div class="flex-1 flex-box flex-end">
                  <div class="fs18">
                    <span class="red">404471</span>
                    <span class="col-9">人浏览</span>
                  </div>
                  <div class="fs18 ml60">
                    <span class="red">404471</span>
                    <span class="col-9">人委托</span>
                  </div>
                  <div class="fs18 ml60 flex-box pointer">
                    <img class="icon-zan mr10" src="@/assets/icon/icon_fabulous.png" alt="" />
                    <span class="red">404471</span>
                    <span class="col-9">人点赞</span>
                  </div>
                </div>
              </div>
              <div class="line-title2 fs20 col-3">平台审核</div>
              <div class="flex-box flex-wrap flex-col-start fs16 col-3 pb10 m-hairline--bottom">
                <div class="feature flex-box mt30" v-for="(item, index) in featureList" :key="index">
                  <img class="icon mr10" src="@/assets/icon/icon_circle_right.png" alt="" />
                  <div>{{ item }}</div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>

        <!-- 团队内容 -->
        <div class="pt30 flex-box mt40">
          <img class="img-head mr30" :src="team.logo_image_url" alt="" />
          <div class="flex-1">
            <div class="fs30 col-3 mb20">{{ team.name }}</div>
            <div class="team-label">{{ team.label }}</div>
          </div>
        </div>
      </div>
      <!-- 中标指数 -->
      <div class="bid-index bg-f7">
        <div class="w1220">
          <div class="fs34 col-3 tc mb40">中标指数</div>
          <el-rate class="mb30" v-model="team.winning" disabled></el-rate>
          <div class="fs16 col-3 tc pt30">由全民代拍跟踪该团队近3个月的平均命中率来制定 命中率越高，中标指数星越多</div>
        </div>
      </div>
      <!--  -->
      <div class="pt40 pb60 w1220 col-3">
        <div class="fs34 col-3 mb30">代拍流程</div>
        <div class="step-row flex-box flex-between ptb30 m-hairline--bottom fs20 col-6 tc mb30">
          <div>
            <img class="img" src="@/assets/icon/icon_upload.png" alt="" />
            <div>1.上传标书</div>
          </div>
          <img class="icon" src="@/assets/icon/icon_step.png" alt="" />
          <div>
            <img class="img" src="@/assets/icon/icon_review.png" alt="" />
            <div>2.平台审核</div>
          </div>
          <img class="icon" src="@/assets/icon/icon_step.png" alt="" />
          <div>
            <img class="img" src="@/assets/icon/icon_pay.png" alt="" />
            <div>3.拍中付款</div>
          </div>
        </div>
        <div class="flex-box flex-col-start mb40">
          <div class="flex-1">
            <div class="line-title3 mb20">团队介绍</div>
            <div class="fs20" style="line-height: 36px">{{ team.info }}</div>
          </div>
          <div class="flex-1 pl30">
            <!-- <div class="hit-rate-chart" ref="rate_ref"></div> -->
            <img class="hit-rate-chart"  :src="team.rate_image_url" />
          </div>
        </div>
      <div class="flex-box mb30">
          <div class="fs34 col-3 flex-1">代拍套餐</div>
          <div class="fs20 col-9">累计 {{ team.num }}人委托</div>
        </div>
        <div class="flex-box flex-wrap flex-col-start fs16 col-3">
          <div class="taocan flex-box" :class="{ active: item.id == setId }" @click="taocanClick(item.id)" v-for="(item, index) in team.teamset" :key="index">
            <div class="flex-1">{{ item.price }}¥</div>
            <div>代拍{{ item.frequency }}次，不中赔{{ item.compensation_price }}¥</div>
          </div>
        </div>
        <div @click="goPlace" class="btn-weituo pointer">立即委托</div>

        <div class="line-title3 mb25">用户评价</div>
        <div class="case-list">
          <div class="mb10 col-3" v-for="(item, index) in list" :key="index">
            <div class="fs20 mb20">{{ item.name }}</div>
            <div class="mb20 fs18">{{ item.content }}</div>
            <div class="flex-box flex-wrap flex-col-start">
              <img class="img1" :src="item.image_url" alt="" />
            </div>
          </div>
          <div v-if="!nodata" class="mb10 col-3">
            <div class="fs20 mb20">暂无评价</div>
          </div>
        </div>
        <div v-show="currentPage < totalPage" @click="getlist()" class="g-btn-more">查看更多</div>
        <div class="line-title3 mb20">代拍说明</div>
        <div class="fs18">上海理工团队给力！</div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/header/Header.vue'
export default {
  name: 'TeamDetail',
  components: { Header },
  data() {
    return {
      featureList: ['实名认证', '实地考察', '线下签约', '预付保证金', '先行赔付', '三重考核', '淘汰机制', '评级制度', '漏拍赔付'],
      rateValue: 3,
      taocanIdx: 0,
      id: 0,
      setId: 0,
      team: {
        teamset: [],
        rate_image_url:""
      },
      token: '',
      nodata: 1,
      currentPage: 0,
      currentSize: 10,
      totalPage: 1,
      list: [],
      chartInstance: null
    }
  },
  created() {
    this.token = this.$storage.get('token')
    this.id = this.$route.query.id
  },
  mounted() {
    this.getTeam()
    this.getlist()
    // this.initChart()
    scrollTo({
      top: 0
    })
  },
  methods: {
    taocanClick(i) {
      this.setId = i
    },
    goPlace() {
      if (!this.token) {
        this.$router.push('/login')
      }
      if (this.setId == 0) {
        this.$message.error('请选择套餐')
        return false
      }
      this.$router.push({ path: '/placeOrder', query: { id: this.setId } })
    },
    getTeam() {
      var params = { id: this.id }
      this.$http
        .get('/api/team/detail', {
          params
        })
        .then(res => {
          this.team = res.data.data
        })
    },
    getlist() {
      this.currentPage++
      if (this.currentPage > this.totalPage) {
        return false
      }
      var params = {
        page: this.currentPage,
        id: this.id
      }
      this.$http
        .get('/api/team/commentlist', {
          params
        })
        .then(res => {
          this.totalPage = res.data.data.last_page
          if (this.currentPage == 1) {
            this.list = res.data.data.data
            if (res.data.data.data.length == 0) {
              this.nodata = 0
            } else {
              this.nodata = 1
            }
          } else {
            this.list = this.list.concat(res.data.data.data)
          }
        })
    }
    // initChart() {
    //   this.chartInstance = this.$echarts.init(this.$refs.rate_ref)
    //   const initOption = {
    //     series: [
    //       {
    //         name: '国拍平均命中率',
    //         type: 'line',
    //         stack: 'Total',
    //         areaStyle: {},
    //         emphasis: {
    //           focus: 'series'
    //         },
    //         data: [10, 13, 11, 34, 90, 30, 10]
    //       },
    //       {
    //         name: '团队命中率',
    //         type: 'line',
    //         stack: 'Total',
    //         areaStyle: {},
    //         emphasis: {
    //           focus: 'series'
    //         },
    //         data: [20, 82, 91, 34, 20, 30, 10]
    //       }
    //     ],
    //     title: {
    //       text: '近半年历史中标率'
    //     },
    //     tooltip: {
    //       trigger: 'axis',
    //       axisPointer: {
    //         type: 'cross',
    //         label: {
    //           backgroundColor: '#6a7985'
    //         }
    //       }
    //     },
    //     legend: {
    //       data: ['国拍平均命中率', '团队命中率']
    //     },
    //     toolbox: {
    //       feature: {
    //         saveAsImage: {}
    //       }
    //     },
    //     grid: {
    //       left: '3%',
    //       right: '4%',
    //       bottom: '3%',
    //       containLabel: true
    //     },
    //     xAxis: [
    //       {
    //         type: 'category',
    //         boundaryGap: false,
    //         data: ['1', '2', '3', '4', '5', '6', '7']
    //       }
    //     ],
    //     yAxis: [
    //       {
    //         type: 'value'
    //       }
    //     ]
    //   }
    //   this.chartInstance.setOption(initOption)
    // }
  }
}
</script>
<style lang="less" scoped>
.team-detail {
  .line-title1 {
    position: relative;
  }
  .line-title1::after {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    width: 141px;
    height: 1px;
    background: #4594d9;
  }
  .icon-zan {
    display: block;
    width: 26px;
    height: 26px;
  }
  .line-title2 {
    position: relative;
    padding-left: 26px;
  }
  .line-title2::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 6px;
    height: 30px;
    background: #ff0000;
  }
  .feature {
    width: calc(100% / 6);
    .icon {
      display: block;
      width: 24px;
      height: 24px;
    }
  }
  .img-head {
    display: block;
    width: 102px;
    height: 102px;
    border-radius: 50%;
  }
  .team-label {
    width: 240px;
    height: 30px;
    background: #d4f2fc;
    border-radius: 4px;
    font-size: 16px;
    color: #0a9bb8;
    line-height: 30px;
    text-align: center;
  }
}
.bid-index {
  padding: 60px 0 50px;
}
.taocan {
  margin: 0 60px 20px 0;
  padding: 0 30px;
  width: 580px;
  height: 70px;
  background: #f6f6f6;
  border-radius: 10px;
  cursor: pointer;
}
.taocan:nth-of-type(2n) {
  margin: 0 0 20px;
}
.taocan.active {
  background: var(--yellow);
}
.btn-weituo {
  margin: 40px auto 70px;
  display: block;
  width: 300px;
  height: 70px;
  background: #f0c96e;
  border-radius: 10px;
  font-size: 20px;
  color: #ffffff;
  line-height: 70px;
  text-align: center;
}

.step-row {
  .img {
    margin: 0 auto 10px;
    display: block;
    width: 80px;
    height: 80px;
  }
  .icon {
    display: block;
    width: 64px;
    height: 64px;
  }
}
.line-title3 {
  position: relative;
  font-size: 24px;
  line-height: 28px;
}
.line-title3::after {
  content: '';
  margin-left: 10px;
  display: inline-block;
  width: 5px;
  height: 22px;
  background: #f6e65f;
  vertical-align: -2px;
}
.case-list {
  .img1 {
    margin: 0 10px 10px 0;
    display: block;
    width: 200px;
    height: 130px;
  }
}
.g-btn-more {
  background: #f3f3f3;
  border: 0;
}
.hit-rate-chart {
  display: block;
  width: 100%;
  height: 300px;
}
</style>
