import { render, staticRenderFns } from "./OperationTeach.vue?vue&type=template&id=961c747e&scoped=true&"
import script from "./OperationTeach.vue?vue&type=script&lang=js&"
export * from "./OperationTeach.vue?vue&type=script&lang=js&"
import style0 from "./OperationTeach.vue?vue&type=style&index=0&id=961c747e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "961c747e",
  null
  
)

export default component.exports