<template>
  <div>
    <div class="g-head-box flex-box col-3 mb30">
      <div class="fs18">操作教学</div>
    </div>
    <div class="operation fs16 col-3">
      
      <img v-for="(item,index) in images" :key="index" class="mb25" style="width:450px;height:auto" :src="item" alt="" />

      <div v-html="artcile.content">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OperationTeach',
  data() {
    return {
      leftIdx: 0,
      images:[],
      artcile:{
         content:'',
      }
    }
  },
 created(){
    this.getTeach()
  },
  methods:{
     getTeach(){
         var that =  this;
        var params = {action:'pc'};
        that.$http.get("/api/common/teach", {
          params
        }).then((res) => {
            that.artcile = res.data.data
            that.images = res.data.data.images
        });        
     }
  }
}
</script>
<style lang="less" scoped>
.operation {
  padding: 0 0 0 40px;
  .img-logo {
    display: block;
    width: 212.18px;
    height: 63.07px;
  }
  .intro {
    margin: 0 0 85px;
    line-height: 28px;
  }
}
</style>
