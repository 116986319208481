<template>
  <div class="">
    <Header :link-idx="1" />
    <div class="mt80 g-min-height">
      <img class="img-top" src="@/assets/images/img_top.png" alt="" />
      <div class="case-box w1220">
        <el-breadcrumb class="mb30" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">拍牌</el-breadcrumb-item>
          <el-breadcrumb-item>中标案例</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="case-list">
          <div class="mb10 col-3" v-for="(item, index) in list" :key="index">
            <div class="fs20 fwb mb20">{{ item.name }}</div>
            <div class="mb20 fs18">{{ item.content }}</div>
            <div class="flex-box flex-wrap flex-col-start">
              <img class="img1" :src="item.image_url" alt="" />
              <div class="result fs18 white">
                <div class="mb20">拍手名称:{{ item.team_name ? item.team_name : '' }}</div>
                <div class="mb15">代拍价格:{{ item.price }}¥</div>
                <div class="">中标时间:{{ item.time }}</div>
              </div>
            </div>
          </div>
        </div>
        <div @click="getlist()" v-if="currentPage < totalPage" class="g-btn-more">查看更多</div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/header/Header.vue'
export default {
  name: 'Case',
  components: { Header },
  data() {
    return {
      currentPage: 0,
      currentSize: 10,
      totalPage: 1,
      list: []
    }
  },
  mounted() {
    this.getlist()
  },
  methods: {
    getlist() {
      this.currentPage++
      if (this.currentPage > this.totalPage) {
        return false
      }
      var params = { page: this.currentPage }
      this.$http
        .get('/api/team/caselist', {
          params
        })
        .then(res => {
          this.totalPage = res.data.data.last_page
          if (res.data.data.data.length === 0) {
            this.nodata = 0
          } else {
            this.nodata = 1
          }
          if (this.currentPage === 1) {
            this.list = res.data.data.data
          } else {
            this.list = this.list.concat(res.data.data.data)
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
.img-top {
  display: block;
  width: 100%;
  height: auto;
}
.case-box {
  padding: 30px 0 0;
  .case-list {
    .img1 {
      margin: 0 20px 20px 0;
      display: block;
      width: 272px;
      height: 176px;
    }
    .result {
      margin: 0 20px 20px 0;
      padding: 20px 30px;
      background: #f0c96e;
    }
  }
}
</style>
