<template>
  <div>
    <Header :link-idx="3" />
    <div class="mt80 g-min-height ptb30 w1220">
      <div class="flex-box flex-col-start">
        <div class="left-wrap col-3 fs18">
          <div class="title mb10">个人中心</div>
          <div :class="{ active: leftIdx == 0 }" @click="leftTabClick(0)">
            <router-link class="ptb20" to="/profile">个人信息</router-link>
          </div>
          <div :class="{ active: leftIdx == 1 }" @click="leftTabClick(1)">
            <router-link class="ptb20" to="/myOrder">我的订单</router-link>
          </div>
          <div :class="{ active: leftIdx == 2 }" @click="leftTabClick(2)">
            <router-link class="ptb20" to="/myCoupon">我的优惠券</router-link>
          </div>
          <!-- 
          <div :class="{ active: leftIdx == 3 }" @click="leftTabClick(3)">
            <router-link class="ptb20" to="/balance">余额明细</router-link>
          </div>
          -->
          <div :class="{ active: leftIdx == 4 }" @click="leftTabClick(4)">
            <router-link class="ptb20" to="/operationTeach">操作教学</router-link>
          </div>
          <div :class="{ active: leftIdx == 5 }" @click="leftTabClick(5)">
            <router-link class="ptb20" to="/kefu">联系客服</router-link>
          </div>
          <div :class="{ active: leftIdx == 6 }" @click="leftTabClick(6)">
            <router-link class="ptb20" to="/about">关于我们</router-link>
          </div>
          <div class="ptb20 pointer" @click="openDialog">退出登录</div>
        </div>
        <div class="right-wrap flex-1">
          <!-- 路由占位 -->
          <router-view></router-view>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" :show-close="false" :before-close="closeDialog" width="400px" custom-class="cash-dialog radius10">
      <div class="fs18 fwb tc mb20 col-3">提示</div>
      <div class="fs18 tc mb40 col-3">确定要退出登录吗？</div>
      <div class="flex-box flex-center">
        <div class="btn-cancel pointer col-3" @click="closeDialog">取消</div>
        <div class="btn-sure pointer col-3" @click="logout"  >确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Header from '@/components/header/Header.vue'
export default {
  name: 'Mine',
  components: { Header },
  data() {
    return {
      leftIdx: 0,
      dialogVisible: false
    }
  },
  methods: {
    leftTabClick(i) {
      this.leftIdx = i
    },
    openDialog() {
      this.dialogVisible = true
    },
    closeDialog() {
      this.dialogVisible = false
    },
    logout(){
         var that = this;
         that.$message({
            type: 'success',
            message: '已退出登录'
          });
          that.$storage.set('token', '');
          setTimeout(function(){
            that.$router.push('/');
          },1000)
    }
  }
}
</script>
<style lang="less" scoped>
.left-wrap {
  margin: 0 30px 0 0;
  padding: 30px 0 0 60px;
  width: 200px;
  height: 606px;
  background: #fafafa;
  border-radius: 10px;
  .title {
    font-size: 22px;
    line-height: 24px;
  }
  .ptb20 {
    cursor: pointer;
  }
  .active {
    position: relative;
    a {
      color: var(--yellow);
    }
  }
  a {
    display: block;
  }
  .active::before {
    content: '';
    position: absolute;
    left: -18px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 20px;
    background: #f6e65f;
  }
}
.right-wrap {
  width: 990px;
}
.cash-dialog {
  .btn-cancel {
    margin: 0 20px 0 0;
    width: 100px;
    height: 40px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
  .btn-sure {
    width: 100px;
    height: 40px;
    background: #f6e65f;
    border-radius: 4px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
</style>
