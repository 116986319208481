<template>
  <div>
    <div class="g-head-box flex-box flex-between mb30 col-3">
      <div v-for="(item, index) in tabList" :key="index" class="tab-item" :class="{ active: tabIdx == index }" @click="tabClick(index)">{{ item }}</div>
    </div>
    <!-- 审核中 -->
    <div v-if="tabIdx == 0"   >
      <router-link v-for="(item, index) in list" :key="index" :to="{ path: '/orderDetail', query: { id:item.id } }" class="g-order-item ptb30 flex-box flex-col-start flex-around">
        <div>
          <div class="key">代拍价格</div>
          <div class="col-3">{{item.price}}¥</div>
        </div>
        <div>
          <div class="key">标书</div>
          <div class="m-red">{{item.set_id==66?'双标书':'单标书'}}</div>
        </div>
        <div>
          <div class="key">次数</div>
          <div class="col-3">{{item.ourtime}}次({{item.count}}次不中)</div>
        </div>
        <div>
          <div class="key">赔付</div>
          <div class="col-3">{{item.compensation}}¥</div>
        </div>
        <div>
          <div class="key">代拍团队</div>
          <div class="col-3">{{item.team.name}}</div>
        </div>
        <div>
          <div class="key">发布时间</div>
          <div class="col-3">{{item.create_time_text}}</div>
        </div>
        <div>
          <div class="key">任务状态</div>
          <div class="m-red">审核中</div>
        </div>
      </router-link>
    </div>
    <!-- 待付款 -->
    <div v-if="tabIdx == 1">
      <router-link v-for="(item, index) in list" :key="index"  :to="{ path: '/orderDetail', query: { id:item.id } }" class="g-order-item ptb30 flex-box flex-col-start flex-around">
        <div>
          <div class="key">代拍价格</div>
          <div class="col-3">{{item.price}}¥</div>
        </div>
        <div>
          <div class="key">标书</div>
          <div class="m-red">{{item.set_id==66?'双标书':'单标书'}}</div>
        </div>
        <div>
          <div class="key">次数</div>
          <div class="col-3">{{item.ourtime}}次({{item.count}}次不中)</div>
        </div>
        <div>
          <div class="key">赔付</div>
          <div class="col-3">{{item.compensation}}¥</div>
        </div>
        <div>
          <div class="key">代拍团队</div>
          <div class="col-3">{{item.team.name}}</div>
        </div>
        <div>
          <div class="key">发布时间</div>
          <div class="col-3">{{item.create_time_text}}</div>
        </div>
        <div>
          <div class="key">任务状态</div>
          <div class="m-red">待付款</div>
        </div>
        <div class="tc">
          <div class="key">操作</div>
          <div class="btn" @click.prevent="openPayDialog"  :data-id="item.id"    >支付</div>
        </div>
      </router-link>
    </div>
    <!-- 已付款 -->
    <div v-if="tabIdx == 2">
      <router-link v-for="(item, index) in list" :key="index" :to="{ path: '/orderDetail', query: {id:item.id } }" class="g-order-item ptb30 flex-box flex-col-start flex-around">
        <div>
          <div class="key">代拍价格</div>
          <div class="col-3">{{item.price}}¥</div>
        </div>
        <div>
          <div class="key">标书</div>
          <div class="m-red">{{item.set_id==66?'双标书':'单标书'}}</div>
        </div>
        <div>
          <div class="key">次数</div>
          <div class="col-3">{{item.ourtime}}次({{item.count}}次不中)</div>
        </div>
        <div>
          <div class="key">赔付</div>
          <div class="col-3">{{item.compensation}}¥</div>
        </div>
        <div>
          <div class="key">代拍团队</div>
          <div class="col-3">{{item.team.name}}</div>
        </div>
        <div>
          <div class="key">发布时间</div>
          <div class="col-3">{{item.create_time_text}}</div>
        </div>
        <div>
          <div class="key">任务状态</div>
          <div class="m-red">已付款</div>
        </div>
        <div class="tc">
          <div class="key">操作</div>
          <div class="btn" @click.prevent="openAssessDialog"  :data-id="item.id"  >评价</div>
        </div>
      </router-link>
    </div>
    <!-- 进行中 -->
    <div v-if="tabIdx == 3">
      <router-link v-for="(item, index) in list" :key="index"  :to="{ path: '/orderDetail', query: { id:item.id} }" class="g-order-item ptb30 flex-box flex-col-start flex-around">
        <div>
          <div class="key">代拍价格</div>
          <div class="col-3">{{item.price}}¥</div>
        </div>
        <div>
          <div class="key">标书</div>
          <div class="m-red">{{item.set_id==66?'双标书':'单标书'}}</div>
        </div>
        <div>
          <div class="key">次数</div>
          <div class="col-3">{{item.ourtime}}次({{item.count}}次不中)</div>
        </div>
        <div>
          <div class="key">赔付</div>
          <div class="col-3">{{item.compensation}}¥</div>
        </div>
        <div>
          <div class="key">代拍团队</div>
          <div class="col-3">{{item.team.name}}</div>
        </div>
        <div>
          <div class="key">发布时间</div>
          <div class="col-3">{{item.create_time_text}}</div>
        </div>
        <div>
          <div class="key">任务状态</div>
          <div class="m-red">进行中</div>
        </div>
      </router-link>
    </div>
    <!-- 已完成 -->
    <div v-if="tabIdx == 4">
      <router-link v-for="(item, index) in list" :key="index"  :to="{ path: '/orderDetail', query: { id:item.id } }" class="g-order-item ptb30 flex-box flex-col-start flex-around">
        <div>
          <div class="key">代拍价格</div>
          <div class="col-3">{{item.price}}¥</div>
        </div>
        <div>
          <div class="key">标书</div>
          <div class="m-red">{{item.set_id==66?'双标书':'单标书'}}</div>
        </div>
        <div>
          <div class="key">次数</div>
          <div class="col-3">{{item.ourtime}}次({{item.count}}次不中)</div>
        </div>
        <div>
          <div class="key">赔付</div>
          <div class="col-3">{{item.compensation}}¥</div>
        </div>
        <div>
          <div class="key">代拍团队</div>
          <div class="col-3">{{item.team.name}}</div>
        </div>
        <div>
          <div class="key">发布时间</div>
          <div class="col-3">{{item.create_time_text}}</div>
        </div>
        <div>
          <div class="key">任务状态</div>
          <div class="m-red">已评价</div>
        </div>
      </router-link>
    </div>
    <!-- 赔付中 -->
    <div v-if="tabIdx == 5">
      <router-link v-for="(item, index) in list" :key="index" :to="{ path: '/orderDetail', query: { id:item.id } }" class="g-order-item ptb30 flex-box flex-col-start flex-around">
        <div>
          <div class="key">代拍价格</div>
          <div class="col-3">{{item.price}}¥</div>
        </div>
        <div>
          <div class="key">标书</div>
          <div class="m-red">{{item.set_id==66?'双标书':'单标书'}}</div>
        </div>
        <div>
          <div class="key">次数</div>
          <div class="col-3">{{item.ourtime}}次({{item.count}}次不中)</div>
        </div>
        <div>
          <div class="key">赔付</div>
          <div class="col-3">{{item.compensation}}¥</div>
        </div>
        <div>
          <div class="key">代拍团队</div>
          <div class="col-3">{{item.team.name}}</div>
        </div>
        <div>
          <div class="key">发布时间</div>
          <div class="col-3">{{item.create_time_text}}</div>
        </div>
        <div>
          <div class="key">任务状态</div>
          <div class="m-red">赔付中</div>
        </div>
      </router-link>
    </div>
    <!-- 赔付完成 -->
    <div v-if="tabIdx == 6">
      <router-link v-for="(item, index) in list" :key="index" :to="{ path: '/orderDetail', query: { id:item.id } }" class="g-order-item ptb30 flex-box flex-col-start flex-around">
         <div>
          <div class="key">代拍价格</div>
          <div class="col-3">{{item.price}}¥</div>
        </div>
        <div>
          <div class="key">标书</div>
          <div class="m-red">{{item.set_id==66?'双标书':'单标书'}}</div>
        </div>
        <div>
          <div class="key">次数</div>
          <div class="col-3">{{item.ourtime}}次({{item.count}}次不中)</div>
        </div>
        <div>
          <div class="key">赔付</div>
          <div class="col-3">{{item.compensation}}¥</div>
        </div>
        <div>
          <div class="key">代拍团队</div>
          <div class="col-3">{{item.team.name}}</div>
        </div>
        <div>
          <div class="key">发布时间</div>
          <div class="col-3">{{item.create_time_text}}</div>
        </div>
        <div>
          <div class="key">任务状态</div>
          <div class="m-red">赔付完成</div>
        </div>
        <div class="tc">
          <div class="key">操作</div>
          <div class="btn"  @click.prevent="openAssessDialog"  :data-id="item.id"  >评价</div>
        </div>
      </router-link>
    </div>
    <div v-if="!nodata">
        <div>
          <div class="key" style="margin-left: 40px;">暂无订单</div>
        </div>
    </div>

    <!-- 支付弹窗 -->
    <el-dialog :visible.sync="payDialogVisible" :show-close="false" :before-close="closePayDialog" width="990px" custom-class="pay-dialog radius10">
      <div class="flex-box">
        <div class="left">
          <div class="fs18 tc col-3 mb40 fwb">订单支付</div>
          <img class="img-qrcode mb30" :src="qrcode" alt="" />
          <div class="flex-box flex-center">
            <img class="img-wx mr20" src="@/assets/icon/icon_wechat.png" alt="" />
            <div class="fs14 col-3">微信扫码支付</div>
          </div>
        </div>
        <div>
          <div class="flex-box mb20 flex-col-baseline">
            <span class="fs18 col-3">应付金额：</span>
            <span class="fs36 fwb price">{{money}}¥</span>
          </div>
          <!---<div class="fs16 col-3 mb40">优惠券：88元(通用)</div>-->
          <div class="fs14 col-9">支付即同意服务协议</div>
        </div>
      </div>
      <div class="clearfix">
        <div class="btn-sure pointer">确定</div>
        <div @click="closePayDialog" class="btn-cancel pointer">取消</div>
      </div>
    </el-dialog>
    <!-- 评价弹窗 -->
    <el-dialog :visible.sync="assessDialogVisible" :show-close="false" :before-close="closeAssessDialog" width="600px" custom-class="assess-dialog radius10">
      <div class="fs18 col-3 mb20">评价</div>
      <textarea v-model="content" class="textarea m-input mb40" placeholder="请输入评价内容~" ></textarea>
      <div class="clearfix">
        <button  @click="comment" class="btn-submit pointer">提交</button>
        <div @click="closeAssessDialog" class="btn-cancel pointer">取消</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'MyOrder',
  data() {
    return {
      tabIdx: 0,
      tabList: ['审核中', '待付款', '已付款', '进行中', '已评价', '赔付中', '赔付完成'],
      payDialogVisible: false,
      assessDialogVisible: false,
      content: '',
      type:1,
      qrcode:'',
      money:6888,
			list:[],
			nodata:1,
			keyword: '',
      token:'',
			currentPage: 0,
			currentSize: 10,
			totalPage: 1,	
      id:0,
    }
  },
  created(){
    this.token = this.$storage.get('token');
     if(!this.token){
       this.$router.push('/login');
     }
  },
  mounted() {
    this.tabIdx = this.$route.query.tabIdx || 0;
    this.tabClick(0) 
  },
  methods: {
    tabClick(i) {
      this.list=[];
      this.tabIdx = i
      if(this.tabIdx==0){
          this.type = 1
      }
      if(this.tabIdx==1){
          this.type = 3
      }  
      if(this.tabIdx==2){
          this.type = 4
      }     
      if(this.tabIdx==3){
          this.type = 2
      }     
      if(this.tabIdx==4){
          this.type = 7
      }    
      if(this.tabIdx==5){
          this.type = 5
      }    
      if(this.tabIdx==6){
          this.type = 6
      }   
      this.currentPage = 0;
      this.totalPage = 1;
      this.getlist();
      //0 => '未知 订单出错', 
      //1 => '等待后台审核',
      //2 => '审核通过 代拍进行中', 
      //3 => '代拍完成 等待付款', 
      //4 => '付款完成',
      //5 => '赔付中',
      // 6 => '赔付完成',
      // 7 => '已评价'
    },
    // 支付弹窗
    openPayDialog(e) {
      this.id = e.target.dataset.id;
      this.getQrCode();
      //请求二维码接口
      //this.payDialogVisible = true
    },
    closePayDialog() {
      this.payDialogVisible = false;
      //刷新页面
      this.currentPage = 0;
      this.totalPage = 1;
      this.getlist();

    },
    // 评价弹窗
    openAssessDialog(e) {
      //console.log(e.target.dataset.id);
      this.id = e.target.dataset.id
      this.assessDialogVisible = true
    },
    closeAssessDialog() {
      this.assessDialogVisible = false
    },
    inputAssess() {},
    getlist() {
      this.currentPage++
      if (this.currentPage > this.totalPage) {
        return false
      }
      var params = { 
        page: this.currentPage ,
        type:this.type,
        token:this.token
      }
      var loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading'
       });
      this.$http
        .get('/api/order/orderlist', {
          params
        })
        .then(res => {
          loading.close();
          this.totalPage = res.data.data.last_page
          if (res.data.data.data.length === 0) {
            this.nodata = 0
          } else {
            this.nodata = 1
          }
          if (this.currentPage === 1) {
            this.list = res.data.data.data
          } else {
            this.list = this.list.concat(res.data.data.data)
          }
        })
    },
    comment(){
        var that = this;
        if(!that.content){
             that.$message.error('请填写评论内容');
             return false;
        }
        var params = {
					token: that.token,
					id:that.id,
          content:that.content
        }
       var loading = that.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading'
       });
        that.$http.post("/api/order/evaluate", 
          params
        ).then((res) => {
           loading.close();
           that.assessDialogVisible = false
           if(res.data.code==1){
							that.$message.success(res.data.msg);
           }else{
              that.$message.error(res.data.msg);
           }
          if(res.data.code==1){
							//刷新页面
              that.list = [];
              that.currentPage = 0;
              that.totalPage = 1;
              that.getlist();
             
						}
        });   
    },
    getQrCode(){
        var that =  this;
        var params = {token:that.token,id:this.id};
        that.$http.get("/api/order/getOrderQr", {
          params
        }).then((res) => {
           if(res.data.code==1){
              console.log(res.data.data);
              that.money = res.data.data.money;
              that.qrcode = res.data.data.qrcode;
              that.payDialogVisible = true
           }else{
              that.$message.error(res.data.msg);
           }
        });   
    }
  }
}
</script>
<style lang="less" scoped>
.tab-item {
  position: relative;
  height: 80px;
  font-size: 18px;
  line-height: 80px;
  cursor: pointer;
}
.tab-item.active {
  color: var(--yellow);
}
.tab-item.active::before {
  content: '';
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 2px;
  background: #f6e65f;
}

.g-order-item {
  .btn {
    width: 72px;
    height: 30px;
    background: #f6e65f;
    border-radius: 4px;
    font-size: 14px;
    color: #333333;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
  }
}
.pay-dialog {
  .left {
    margin-right: 100px;
  }
  .price {
    color: #f9c764;
  }
  .btn-cancel {
    margin: 30px 0 0;
    float: right;
    width: 100px;
    height: 40px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
  .btn-sure{
    margin: 30px 0 0 30px;
    float: right;
    width: 100px;
    height: 40px;
    background-color: var(--yellow);
    border-radius: 4px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
.assess-dialog {
  .textarea {
    padding: 20px;
    width: 520px;
    height: 300px;
    background: #f7f7f7;
    border-radius: 10px;
  }
  .btn-submit {
    float: right;
    width: 100px;
    height: 40px;
    background: #f6e65f;
    border-radius: 4px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
  .btn-cancel {
    margin: 0 20px 0 0;
    float: right;
    width: 100px;
    height: 40px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
</style>
