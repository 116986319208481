<template>
  <div>
    <Header />
    <div class="login mt80 g-min-height">
      <div class="login-inner col-3">
        <div class="tc fs20 mb30">登录</div>
        <div class="input-row flex-box">
          <img class="icon mr10" src="@/assets/icon/icon_phone.png" />
          <input class="fs20 m-input flex-1" v-model="phone" placeholder="请输入您的手机号" />
          <div v-show="countShow"  @click="sendSms()" class="code">验证码</div>
          <div v-show="!countShow"  class="code">{{countSecond}}s</div>
        </div>
        <div class="input-row flex-box">
          <img class="icon mr10" src="@/assets/icon/icon_lock.png" />
          <input class="fs20 m-input flex-1" v-model="code" placeholder="请输入您的验证码" />
        </div>
        <button @click="login()" class="btn-login">登录</button>
        <div class="fs16 col-9 tc mb40">若未注册账户，登录自动创建账户</div>
        <!---
        <div class="flex-box flex-center btn-wx">
          <img class="icon-wx mr10" src="@/assets/icon/icon_wechat.png" />
          <div class="fs16 col-3">微信登录</div>
        </div>
        -->
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/header/Header.vue'
export default {
  name: 'Login',
  data() {
    return {
      phone: '',
      code: '',
      islogin:0,
      userinfo:[],
      checked:false,
      countSecond: 60,
      countShow: true,
    }
  },
  components: { Header },
  created(){
    
  },
  mounted(){

  },
  methods: {
    sendSms() {
       var that = this;
       if(that.phone==''){
          that.$message.error("请输入手机号码");
          return false;    
       }
       var myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
       if (!myreg.test(that.phone)) {
           that.$message.error("请输入正确的手机号码");
           return false;
       }
       var t;
       var countSecond = that.countSecond;
       var countShow = that.countShow;
       that.countShow = countShow
       t = setInterval(function () {
         countSecond--
         if (countSecond <= 0) {
           countShow = true
           clearInterval(t)
           countSecond = 60
         } else {
           countShow = false
           if (countSecond < 10) {
             countSecond = '0' + countSecond
           }
         }
         that.countSecond = countSecond
         that.countShow = countShow
       }, 1000);
       let params = {
         mobile:that.phone,
         event:'mobilelogin'
       }

      that.$http.get("/api/sms/send", {
          params
        }).then((res) => {
           if(res.data.code==1){
              that.$message.success(res.data.msg);
           }else{
              that.$message.error(res.data.msg);
           }
        });         
     },
     login(){
       var that = this;
       if(that.phone==''){
          that.$message.error("请输入手机号码");
          return false;    
       }
       var myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
       if (!myreg.test(that.phone)) {
           that.$message.error("请输入正确的手机号码");
           return false;
       }
       if(that.code==''){
          that.$message.error("请输入验证码");
          return false;    
       }
       let params = {
         mobile:that.phone,
         code:that.code,
       }
       var loading = that.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading'
       });
      that.$http.get("/api/sms/check", {
          params
        }).then((res) => {
          loading.close();
           if(res.data.code==1){
              that.$message.success(res.data.msg);
              that.$storage.set('token',res.data.data.token);
              that.$storage.set('expiretime',res.data.data.expiretime);
              setTimeout(function(){
                //跳转首页
                that.$router.push('/');
              },1500);
           }else{
              that.$message.error(res.data.msg);
           }
        });     

     }
  }
}
</script>
<style lang="less" scoped>
.login {
  padding: 100px 0 90px;
  .login-inner {
    margin: 0 auto;
    width: 630px;
    .input-row {
      padding: 50px 0 15px;
      border-bottom: 1px solid #efefef;
      .icon {
        display: block;
        width: 41px;
        height: 41px;
      }
      .code {
        width: 72px;
        height: 30px;
        background: #f6e65f;
        border-radius: 4px;
        font-size: 14px;
        color: #333333;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
      }
    }
    .btn-login {
      margin: 70px 0 20px;
      width: 630px;
      height: 70px;
      background: #f6e65f;
      border-radius: 10px;
      font-size: 20px;
      color: #333333;
      line-height: 70px;
      text-align: center;
      cursor: pointer;
    }
    .btn-wx {
      cursor: pointer;
      .icon-wx {
        display: block;
        width: 27px;
        height: 27px;
      }
    }
  }
}
</style>
