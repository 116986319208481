<template>
  <div class="detail">
    <div class="g-head-box flex-box flex-between mb30 col-3">
      <router-link v-for="(item, index) in tabList" :key="index" :to="{ path: '/myOrder', query: { tabIdx: index } }" class="tab-item" :class="{ active: tabIdx == index }">{{ item }}</router-link>
    </div>
    <div class="plr40 pb30 m-hairline--bottom fs16 col-3">
      <div class="flex-box mb30">
        <div class="flex-1 flex-box">
          <div class="key">标书号</div>
          <div>{{order.order_num?order.order_num:"后台审核"}}</div>
        </div>
        <div class="flex-1 flex-box">
          <div class="key">标书状态</div>
          <div class="m-red">{{order.checkinfo_text}}</div>
        </div>
      </div>
      <div class="flex-box mb30">
        <div class="flex-1 flex-box">
          <div class="key">姓名</div>
          <div>{{order.name}}</div>
        </div>
        <div class="flex-1 flex-box">
          <div class="key">身份证</div>
          <div>{{order.id_num}}</div>
        </div>
      </div>
      <div class="flex-box">
        <div class="flex-1 flex-box">
          <div class="key">手机号</div>
          <div>{{order.phone}}</div>
        </div>
      </div>
    </div>
    <div class="plr40 ptb30 fs16 col-3">
      <div class="flex-box mb30">
        <div class="flex-1 flex-box">
          <div class="key">代拍价格</div>
          <div class="yellow flex-box">
            <div class="mr10">*</div>
            <div>{{order.price}}¥</div>
          </div>
        </div>
        <div class="flex-1 flex-box">
          <div class="key">代拍次数</div>
          <div class="yellow flex-box">
            <div class="mr10">*</div>
            <div>{{order.count}}次</div>
          </div>
        </div>
      </div>
      <div class="flex-box mb30">
        <div class="flex-1 flex-box">
          <div class="key">赔付金额</div>
          <div class="yellow flex-box">
            <div class="mr10">*</div>
            <div>{{order.compensation}}¥</div>
          </div>
        </div>
      </div>
    </div>
    <div @click="goContact()"   class="btn-view pointer">查看合同</div>
  </div>
</template>
<script>
export default {
  name: 'OrderDetail',
  data() {
    return {
      id:0,
      token:'',
      order:[],
      tabIdx: 0,
      tabList: ['审核中', '待付款', '已付款', '进行中', '已评价', '赔付中', '赔付完成']
    }
  },
  created(){
    this.id = this.$route.query.id ;
    this.token = this.$storage.get('token');
    if(!this.token){
       this.$router.push('/login');
    }
  },
  mounted() {
    this.tabIdx = this.$route.query.tabIdx;
    this.getOrderDetail();
  },
  methods:{
     goContact(){
        this.$router.push({  path: '/contract',query:{id:this.id} })
     },
     getOrderDetail(){
        var that =  this;
        var params = {id:that.id,token:that.token};
        that.$http.get("/api/order/detail", {
          params
        }).then((res) => {
            if(res.data.code==1){
                that.order = res.data.data;
            }else{
              setTimeout(function(){
                //跳转首页
                that.$router.push('/myOrder');
              },1500);
            }
        });        
     }
  }
}
</script>
<style lang="less" scoped>
.tab-item {
  position: relative;
  height: 80px;
  font-size: 18px;
  line-height: 80px;
  cursor: pointer;
}
.tab-item.active {
  color: var(--yellow);
}
.tab-item.active::before {
  content: '';
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 2px;
  background: #f6e65f;
}
.detail {
  .key {
    width: 164px;
  }
  .m-red {
    color: #ff1f5d;
  }
  .btn-view {
    margin: 0 0 0 40px;
    width: 160px;
    height: 50px;
    background: #f6e65f;
    border-radius: 10px;
    font-size: 16px;
    color: #333333;
    line-height: 50px;
    text-align: center;
  }
}
</style>
