<template>
  <div>
    <Header :link-idx="2" />
    <div class="g-min-height mt80 pt30 pb60 w1220 fs16 col-3">
      <el-breadcrumb  separator-class="el-icon-arrow-right">
        <el-breadcrumb-item   :to="{ path: '/team' }">团队</el-breadcrumb-item>
        <el-breadcrumb-item   :to="{ path: '/teamDetail'  ,query:{ id:order.team_id}  }">团队详情</el-breadcrumb-item>
        <el-breadcrumb-item   :to="{ path: '/placeOrder'  ,query:{ id:id}   }">下单</el-breadcrumb-item>
        <el-breadcrumb-item>签署合同</el-breadcrumb-item>
      </el-breadcrumb>
  
      <div class="team ptb40 mb30 flex-box m-hairline--bottom">
        <img class="img-head mr30" :src="team.logo_image_url" alt="" />
        <div class="flex-1">
          <div class="fs30 col-3 mb20">{{team.name}}</div>
          <div class="team-label">{{team.label}}</div>
        </div>
      </div>
      <div class="contract">
        <div class="mb30">
          甲方自愿支付
          <span class="yellow">{{order.price}}</span>
          元服务费，委托乙方代拍
          <span class="yellow">{{order.frequency}}</span>
          次,如乙方超出期限未完成代拍任务，需赔偿甲方
          <span class="yellow">{{order.compensation_price}}</span>
          元赔付金。漏拍一次赔付100元。因乙方原因未完成代拍任务的，甲方无需支付服务费给乙方。
        </div>
        <div v-for="(item,index) in contract" :key="index">
          <div class="line-title">{{item.title}}</div>
          <div class="mb30">{{item.content}}</div>
        </div>
        <div class="pt40 flex-box fs22">
          <div class="flex-1">
            <div class="mb20">甲方：{{phone}}</div>
            <div class="mb30">时间：{{order.date}}</div>
            <div @click="sureOrder" class="btn-sign pointer">确定签署</div>
          </div>
          <div class="flex-1">
            <div class="mb20">乙方：技蓝网络科技（上海）有限公司</div>
            <div>时间：{{order.date}}</div>
            <img class="icon-agree" src="@/assets/icon/icon_agree.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :show-close="false" :before-close="closeDialog" width="400px" custom-class="contract-dialog radius10">
      <img class="icon-success" src="@/assets/icon/icon_success.png" alt="" />
      <div class="tip">合同签署成功，请等待后台确认</div>
      <div class="tip">【并在我的订单完成后续操作】</div>
      <div  @click="closeDialog"  class="btn-sure pointer">确定</div>
    </el-dialog>
  </div>
</template>
<script>
import Header from '@/components/header/Header.vue'
export default {
  name: 'SignContract',
  components: { Header },
  data() {
    return {
      dialogVisible: false,
				contract: [],
				order: {
          team_id:0
        },
        team:{
          logo_image_url:''
        },
        team_id:0,
				token: "",
				name:"",
				idnum:"",
				phone:"",
				image:"",
				name2:"",
				idnum2:"",
				phone2:"",
				image2:"",
				token2:'',
				type:0,
        typepage:0,
				id:0,
				set_id:0,      
    }
  },
  created(){
      this.token = this.$storage.get('token');
      this.id = this.$route.query.id ;
      if(!this.token){
       this.$router.push('/login');
      }
			this.typepage = this.$route.query.type;
			if(this.typepage==1){
				//双标书的情况下
				this.set_id = 66;
			}
			//标书1信息
      this.type =  this.$storage.get('type');
			this.name =  this.$storage.get('name');
			this.idnum = this.$storage.get('idnum');
			this.phone = this.$storage.get('mobile');
			this.image = this.$storage.get('image');
			//标书2信息
      this.type2 =  this.$storage.get('type2');
			this.name2 =  this.$storage.get('name2');
			this.idnum2 = this.$storage.get('idnum2');
			this.phone2 = this.$storage.get('mobile2');
			this.image2 = this.$storage.get('image2');
			this.getOrderDetail();
      this.getMealTeam();
  },
  mounted(){
    scrollTo({
      top: 0
    })
  },
  methods: {
    openDialog() {
      this.dialogVisible = true
    },
    closeDialog() {
      this.dialogVisible = false
    },
    getOrderDetail(){
        var that =  this;
        var params = {id:that.id,token:that.token,type:that.typepage};
        that.$http.get("/api/order/newcontract", {
          params
        }).then((res) => {
            if(res.data.code==1){
                that.order = res.data.data.order;
                that.contract =res.data.data.contract;
            }else{
              setTimeout(function(){
                //跳转首页
                that.$router.push('/team');
              },1500);
            }
        });        
     },
     getMealTeam(){
      var that =  this;
      if(that.typepage==0){
        var params = {id:that.id,token:that.token};
       }else{
         var params = {id:32,token:that.token};
      } 
        that.$http.get("/api/team/mealteam", {
          params
        }).then((res) => {
            if(res.data.code==1){
                that.team = res.data.data;
                that.team_id = res.data.data.id;
            }
        });  
             
    },
    sureOrder(){
				var that = this;
				var setnum = 0;
				if(that.typepage==1){
					setnum = 66;
				}else{
					setnum = that.id;
				}
      var params ={
						token: that.token,
						set_id:setnum,
						count:that.order.frequency,
						price:that.order.price,
						compensation_price:that.order.compensation_price,
            type: that.type,
						name:that.name,
						id_number:that.idnum,
						phone:that.phone,
						biaoshu_image:that.image,
            type2: that.type2,
						name2:that.name2,
						id_number2:that.idnum2,
						phone2:that.phone2,
						biaoshu_image2:that.image2,
						remarks:'',
						order_num1:'',
						password1:'',
						order_num2:'',
						password2:'',
					}
        var loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading'
          });
        that.$http.post("/api/order/create", 
          params
        ).then((res) => {
           loading.close();
           if(res.data.code==1){
							//that.$message.success(res.data.msg);
              that.openDialog();
           }else{
              that.$message.error(res.data.msg);
           }
        });     
    }
  }
}
</script>
<style lang="less" scoped>
.team {
  .img-head {
    display: block;
    width: 102px;
    height: 102px;
    border-radius: 50%;
  }
  .team-label {
    width: 240px;
    height: 30px;
    background: #d4f2fc;
    border-radius: 4px;
    font-size: 16px;
    color: #0a9bb8;
    line-height: 30px;
    text-align: center;
  }
}
.contract {
  line-height: 32px;
  .btn-sign {
    display: block;
    width: 196px;
    height: 70px;
    background: #f6e65f;
    border-radius: 99px;
    font-size: 18px;
    line-height: 70px;
    text-align: center;
  }
  .icon-agree {
    display: block;
    width: 143px;
    height: 104px;
  }
}
.line-title {
  position: relative;
  margin: 0 0 20px;
  padding-left: 26px;
  font-size: 20px;
  font-weight: bold;
}
.line-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 6px;
  height: 30px;
  background: var(--yellow);
}
.contract-dialog {
  .icon-success {
    margin: 0 auto 20px;
    display: block;
    width: 84px;
    height: 84px;
  }
  .tip {
    font-size: 16px;
    color: #333333;
    line-height: 30px;
    text-align: center;
  }
  .btn-sure {
    margin: 40px auto 0;
    width: 200px;
    height: 40px;
    background: #f6e65f;
    border-radius: 4px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
</style>
