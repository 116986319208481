<template>
  <div>
    <div class="g-head-box flex-box col-3 mb30">
      <div class="fs18 mr10">个人信息</div>
      <div class="label">普通用户</div>
    </div>
    <div class="profile">
      <div class="flex-box mb30">
        <div class="key">头像</div>
        <div class="head">
          <img class="img-head" :src="headimgurl" alt="" />
          <input type="file" />
        </div>
      </div>
      <div class="flex-box mb30">
        <div class="key">昵称</div>
        <div class="input-box">
          <input class="m-input" v-model="name"  placeholder="请输入昵称"   type="text" />
        </div>
      </div>
      <div class="flex-box mb30">
        <div class="key">手机</div>
        <div class="fs16 col-3">{{mobile}}</div>
      </div>
      <div class="flex-box mb30">
        <div class="key">邮箱</div>
        <div class="input-box" style="width: 180px">
          <input class="m-input" v-model="email"  placeholder="请输入邮箱"  type="email" />
        </div>
      </div>
      <div class="flex-box mb30">
        <div class="key">支付宝账号</div>
        <div class="input-box">
          <input class="m-input" v-model="zfb" placeholder="请输入支付宝账号" type="text" />
        </div>
      </div>
      <button @click="saveinfo()"  class="btn-save pointer">保存</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Profile',
  data() {
    return {
      leftIdx: 0,
      token:'',
      headimgurl:'',
      userinfo:[],
      name:'',
      mobile:'',
      email:'',
      zfb:''
    }
  },
  created(){
    this.token = this.$storage.get('token');
     if(!this.token){
       this.$router.push('/login');
     }
  },
  mounted(){
     this.getUserInfo()
  },
  methods: {
     getUserInfo(){
        var that =  this;
        var params = {token:that.token};
        that.$http.get("/api/user/profile", {
          params
        }).then((res) => {
           if(res.data.code==1){
              
							that.userinfo = res.data.data;
              that.headimgurl = res.data.data.headimgurl;
							that.name = res.data.data.nickname;
							that.mobile = res.data.data.phoneNumber;
							that.email	= res.data.data.email;
							that.zfb = res.data.data.alipay;
           }else{
              that.$message.error(res.data.msg);
           }
        });       
     },
     saveinfo(){
        var that =  this;
        if(!that.name){
            that.$message.error('请输入姓名');
            return false;
        }
        if(!that.email){
            that.$message.error('请输入邮箱');
            return false;
        }
        if(!that.zfb){
            that.$message.error('请输入支付宝账号');
            return false;
        }
        var params = {
					token: that.token,
					nickname :that.name,
					email:that.email,
					alipay:that.zfb
          };
        that.$http.post("/api/user/editprofile", 
          params
        ).then((res) => {
           if(res.data.code==1){
							that.$message.success(res.data.msg);
           }else{
              that.$message.error(res.data.msg);
           }
        });     
     }
  }
}
</script>
<style lang="less" scoped>
.g-head-box {
  .label {
    padding: 0 10px;
    height: 21px;
    background: #f6e65f;
    border-radius: 7px;
    font-size: 12px;
    color: #333333;
    line-height: 22px;
  }
}
.profile {
  padding: 0 0 0 40px;
  .key {
    width: 164px;
    font-size: 16px;
    line-height: 18px;
  }
  .head {
    position: relative;
    width: 60px;
    height: 60px;
    .img-head {
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
  .input-box {
    padding: 0 10px;
    width: 135px;
    height: 30px;
    border: 1px solid #f0f0f0;
    .m-input {
      height: 30px;
    }
  }
  .btn-save {
    width: 140px;
    height: 50px;
    background: #f6e65f;
    border-radius: 10px;
    font-size: 16px;
    color: #333333;
    line-height: 50px;
    text-align: center;
  }
}
</style>
