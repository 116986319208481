<template>
  <div>
    <div class="g-head-box flex-box col-3 mb30">
      <div class="fs18 flex-1">余额明细</div>
      <div class="fs16 mr20">4961.00¥</div>
      <div class="btn-cash" @click="openDialog">提现</div>
    </div>
    <div class="g-order-item ptb30 flex-box flex-col-start flex-around" v-for="(item, index) in 5" :key="index">
      <div>
        <div class="key">流水号{{ item }}</div>
        <div class="col-3">1212121212121</div>
      </div>
      <div>
        <div class="key">金额</div>
        <div class="yellow">150.00¥</div>
      </div>
      <div>
        <div class="key">余额</div>
        <div class="col-3">2500.00¥</div>
      </div>
      <div>
        <div class="key">时间</div>
        <div class="col-3">2020-02-02 15:15:15</div>
      </div>
      <div>
        <div class="key">说明</div>
        <div class="m-red">赔付</div>
        <div class="m-red">提现(未到账)</div>
        <div class="m-red">提现(已到账)</div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" :show-close="false" :before-close="closeDialog" width="400px" custom-class="cash-dialog radius10">
      <div class="flex-box col-3 mb30">
        <div class="fs18 flex-1">提现</div>
        <div class="fs14">余额：4961.00¥</div>
      </div>
      <input class="m-input fs14" placeholder="请输入提现金额" type="text" />
      <div class="btn-sure pointer">提现</div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'Balance',
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    openDialog() {
      this.dialogVisible = true
    },
    closeDialog() {
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
.g-head-box {
  .btn-cash {
    width: 72px;
    height: 30px;
    background: #f6e65f;
    border-radius: 4px;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
  }
}

.cash-dialog {
  .btn-sure {
    margin: 80px auto 0;
    width: 320px;
    height: 50px;
    background: #f6e65f;
    border-radius: 4px;
    font-size: 16px;
    line-height: 50px;
    text-align: center;
  }
}
</style>
