<template>
  <div>
    <div class="g-head-box flex-box fs18 col-9 mb30">
      <div class="tab-item" :class="{ active: tabIdx == 1 }" @click="tabClick(1)">未使用</div>
      <div class="tab-item" :class="{ active: tabIdx == 0 }" @click="tabClick(0)">已使用</div>
    </div>
    <div v-if="tabIdx === 1" class="flex-box flex-col-start flex-wrap">
      <div class="coupon-item useful" v-for="(item, index) in list" :key="index">
        <div class="mb15">{{item.title}}</div>
        <div>有效期{{item.start_time}}至{{item.end_time}}</div>
      </div>
    </div>
    <div v-if="tabIdx === 0" class="flex-box flex-col-start">
      <div class="coupon-item useless" v-for="(item, index) in list" :key="index">
        <div class="mb15">{{item.title}}</div>
        <div>有效期{{item.start_time}}至{{item.end_time}}</div>
      </div>
    </div>
     <div v-if="!nodata" class="flex-box flex-col-start">
        <div class="mb15" style="margin-left:40px;">暂无优惠券</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MyCoupon',
  data() {
    return {
      tabIdx: 1,
      token:'',
			list:[],
			nodata:1,
			keyword: '',
			currentPage: 0,
			currentSize: 10,
			totalPage: 1,	
    }
  },
  created(){
    this.token = this.$storage.get('token');
     if(!this.token){
       this.$router.push('/login');
     }
  },
  mounted(){
     this.getlist();
  },
  methods: {
    tabClick(i) {
       this.list=[];
      this.currentPage = 0;
      this.totalPage = 1;
      this.tabIdx = i;
      this.getlist();
    },
    getlist() {
      this.currentPage++
      if (this.currentPage > this.totalPage) {
        return false
      }
      var params = { 
        page: this.currentPage ,
        type:this.tabIdx,
        token:this.token
      }
      this.$http
        .get('/api/coupon/list', {
          params
        })
        .then(res => {
          this.totalPage = res.data.data.last_page
          if (res.data.data.data.length === 0) {
            this.nodata = 0
          } else {
            this.nodata = 1
          }
          if (this.currentPage === 1) {
            this.list = res.data.data.data
          } else {
            this.list = this.list.concat(res.data.data.data)
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
.tab-item {
  margin: 0 100px 0 0;
  height: 80px;
  line-height: 80px;
  cursor: pointer;
}
.tab-item.active {
  color: #333;
}
.coupon-item {
  padding: 35px 0 0 40px;
  margin: 0 50px 20px 0;
  width: 450px;
  height: 125px;
  background-size: 450px 125px;
  background-repeat: no-repeat;
  color: var(--white);
}
.coupon-item:nth-of-type(2n) {
  margin: 0 0 20px;
}
.coupon-item.useful {
  background-image: url('../../assets/icon/ticket_useful.png');
}
.coupon-item.useless {
  background-image: url('../../assets/icon/ticket_useless.png');
}
</style>
